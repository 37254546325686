<template>

        <p class="title is-4 my-3">Van wie wil je de verdagdagen bijhouden?</p>

        <div class="buttons has-addons mb-2">
        <button :class="['button','is-primary', {'is-outlined': forWho !== 'other'}]" @click="this.forWho = 'other'">Iemand anders</button>
          <button :class="['button','is-primary', {'is-outlined': forWho !== 'self'}]" @click="this.forWho = 'self'">Mezelf</button>
        </div>
        <template v-if="forWho">

        <div class="field" v-if="forWho === 'other'">
          <label class="label" for="friendname">Hoe heet die persoon?</label>
          <div class="control">
            <input class="input" id="friendname" v-model.lazy="friendName" :placeholder="generateExampleName()">
          </div>
        </div>

        <div class="field" v-if="friendName || forWho === 'self'">
          <label class="label" for="birthdate" v-if="forWho === 'other'">Wanneer is {{ friendName ? friendName : 'die' }} geboren?</label>
          <label class="label" for="birthdate" v-if="forWho === 'self'">Wanneer ben je geboren?</label>
          <div class="control">
            <input class="input" type="date" id="birthdate" v-model.lazy="birthDate">
          </div>
        </div>


        <label class="label" v-if="friendAgeInDays <= -1">Deze site werkt alleen voor mensen die al geboren zijn. Nog even afwachten!</label>
        <template v-else-if="friendAgeInDays">
          <div class="field" >
            <label class="label" v-if="forWho === 'other'">{{ friendName ? friendName : 'Deze persoon' }} is nu <b>{{formatNum(friendAgeInDays)}}</b> dagen oud! Welke verdagdagen wil je bijhouden?</label>
            <label class="label" v-if="forWho === 'self'">Je bent nu <b>{{formatNum(friendAgeInDays)}}</b> dagen oud! Welke verdagdagen wil je bijhouden?</label>
            <div class="buttons">
              <template v-for="amount in milestoneOptions" :key="amount.number">
                <button :class="[{'is-primary': amount.isActive}, 'button', 'is-rounded', 'is-responsive', 'milestone-button']" v-if="amount.number > friendAgeInDays" @click="addSelectedMilestone(amount.number); amount.isActive = !amount.isActive">{{ formatNum(amount.number) }}<br /><small>{{formatDate(addDates(birthDate, amount.number))}}</small></button>
              </template>
            </div>
          </div>


        </template>

          <div class="field buttons" v-if="selectedMilestones.length > 0">
            <button class="button is-responsive is-primary" @click="generateCalendar(); generatedCalendar.download('Verdagdagskalender.ics');">
      <span class="icon">
        <font-awesome-icon icon="calendar-plus" />
      </span>
              <span>Voeg toe aan kalender</span>
            </button>

            <button class="button  is-outlined is-responsive" @click="forWho = '';">
      <span class="icon">
        <font-awesome-icon icon="rotate-right" />
      </span>
              <span>Opnieuw beginnen</span>
            </button>

            <a href="" target="popup" :onclick="generateTweet()"  v-if="selectedMilestones.length > 0"  class="button is-responsive is-info">
              <span class="icon">
                <font-awesome-icon :icon="['fab', 'twitter']" />
              </span>
              <span>Delen op Twitter</span>
            </a>

          </div>

        </template>

</template>

<script>

import { ICalendar } from 'datebook';
import { useMeta } from 'vue-meta';

export default {
  name: 'SignUpFormMain',
  setup () {
    useMeta({ title: 'Start' })
  },
  data() {
    return {
      forWho: '',
      friendName: '',
      birthDate: 0,
      friendAgeInDays: '',
      today: new Date(),
      submitState: '',
      generatedCalendar:'',
      milestoneOptions: [{number: 1000, isActive: false},{number: 2500, isActive: false},{number: 5000, isActive: false},{number: 7500, isActive: false},{number: 10000, isActive: false},{number: 11111, isActive: false},{number: 12345, isActive: false},{number: 15000, isActive: false},{number: 20000, isActive: false},{number: 22222, isActive: false},{number: 25000, isActive: false},{number: 30000, isActive: false}],
      selectedMilestones: []
    }
  },
  watch: {
    birthDate(newDate) { // calculate the age in days
      if(newDate !== null) {
        const dateItem = new Date(newDate)
        if (dateItem > new Date('1900-01-01')){ // don't start counting until entire year has been typed in
          this.calculateAgeDays(dateItem)
        }
      }
    },
    forWho() { // reset values when switching forms
      this.friendName = '';
      this.birthDate = null;
      this.friendAgeInDays = '';
      this.selectedMilestones = [];
      for(let m=0; m < this.milestoneOptions.length; m++) {
        this.milestoneOptions[m].isActive = false
      }
    }
  },
  methods: {
    generateExampleName(){ // picks a name from the list for the placeholder
      const names = ['Isabelle', 'Marijn', 'Jeremy', 'Roderick', 'Manon', 'Simon', 'Björn', 'Carolien', 'Zoë', 'Marco', 'Nino', 'Harmen', 'Annemiek', 'Sabrina', 'Loïs', 'Caitlin', 'Nina', 'Meike', 'Manuela', 'Kayleigh', 'Christina', 'Andries', 'Benthe', 'Louise', 'Bibi', 'Wesley', 'Roeland', 'Maxim', 'Nathan', 'Jeanine', 'Sam', 'Kenneth', 'Mario', 'Michael', 'Guusje', 'William', 'Ramona', 'Jacoba', 'Mariëlle', 'Mees', 'Dimitri', 'Leroy', 'Alicia', 'Bo', 'Marcella', 'Carmen', 'Joseph', 'Janneke', 'Dorien', 'Menno', 'Sylvana', 'Jason', 'Calvin', 'Hanna', 'Esra', 'Jeffrey', 'Shanna', 'Thom', 'Simone', 'Coen', 'Irene', 'Tamara', 'Daniel', 'Gerben', 'Lex', 'Petronella', 'Sharon', 'Loek', 'Mick', 'Jacob', 'Astrid', 'Nicky', 'Catharina', 'Marlou', 'Dirkje', 'Sietse', 'Babette', 'Jack', 'Eline', 'Patty', 'Fatih', 'Lara', 'Desirée', 'Rowena', 'Rachelle', 'Annabel', 'Ilona', 'Sabine', 'Lorenzo', 'Marieke', 'Ronald', 'Nathalie', 'Evy', 'Nynke', 'Kirsten', 'Robin', 'Khalid', 'Bernard', 'Charlotte', 'Benjamin', 'Han', 'Leia', 'Suus', 'Wouter', 'Rogier', 'Henk', 'Els', 'Merel', 'Hugo', 'Isaiah', 'Liorah', 'Emma', 'Joris', 'Nadia', 'Marty', ];
      const random = Math.floor(Math.random() * names.length);
      return names[random];
    },
    formatNum(num){
      return num.toLocaleString('nl-NL')
    },
    formatDate(date){
      return date.toLocaleDateString('nl-NL')
    },
    calculateAgeDays(birthDate){
      const days = (this.today - birthDate) / (1000 * 60 * 60 * 24) // convert from milliseconds to days
      this.friendAgeInDays = Math.floor(days)
    },
    addSelectedMilestone(amount){ // fills the array with selected milestones
      if (this.selectedMilestones.includes(amount)) {
        this.selectedMilestones = this.selectedMilestones.filter(function (f) {
          return f !== amount
        })
      } else {
        this.selectedMilestones.push(amount)
        this.selectedMilestones.sort()
      }
    },
    addDates(start, delta){
      let result = new Date(start)
      result.setDate(result.getDate() + delta + 1)
      return result
    },
    generateTweet(){
      let tweetText = ''
      if (this.forWho === 'self') {
        tweetText = 'Ik ben binnenkort '+ this.formatNum(this.selectedMilestones[0]) +' dagen oud! Bekijk jouw verdagdagen op verdagdagskalender.nl'
      } else {
        tweetText = 'Eén van mijn vrienden is binnenkort ' +  this.formatNum(this.selectedMilestones[0]) + ' dagen oud! Bekijk jouw verdagdagen op verdagdagskalender.nl'
      }

      return "window.open('https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweetText) + "' ,'popup','width=600,height=600'); return false;"
    },
    generateCalendar(){
      for(let i = 0; i < this.selectedMilestones.length; i++){
        let milestone = this.selectedMilestones[i];
        let milestoneDate = this.addDates(this.birthDate, milestone)
        let name = (this.forWho === 'self' ? 'Mijn ' : this.friendName +'s ')
        let opts = {
          title: name + this.formatNum(milestone) + 'e verdagdag',
          start: milestoneDate,
        }
        if(i === 0) {
          this.generatedCalendar = new ICalendar(opts)
        } else {
          this.generatedCalendar.addEvent(new ICalendar(opts))
        }
      }
      this.generatedCalendar
          .addProperty('URL', 'https://www.verdagdagskalender.nl')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.milestone-button{
  flex-direction: column;
  height: 3em !important;
}

.milestone-button small {
  font-size: 0.6em;
}
</style>
