<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Verdagdagskalender` : `Verdagdagskalender` }}</template>
  </metainfo>
  <section class="section">
    <div class="container">
      <div class="columns ">
        <div class="column is-8 is-offset-2">
      <div class="box " id="main-box">
        <img alt="Logo" id="logo" src="./assets/celebration.png" >
      <div class="content">
        <router-link to="/"><p class="title">
          Ver&shy;dag&shy;dags&shy;kalender.nl </p>
        </router-link>

        <template v-if="$route.path !== '/over'">
        <p class="subtitle">
          Je verjaardag is maar eens per jaar, een verdagdag vier je elke dag!
        </p>
        </template>
        <router-view></router-view>



      </div>


      </div>
          <router-link to="/over" class="ml-5 has-text-white is-size-7 is-aligned-right" v-if="$route.path !== '/over'">Over deze site</router-link>
        </div>
    </div>
    </div>
  </section>

</template>

<script>

import { useMeta } from 'vue-meta'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  setup () {
    useMeta({
      title: '',
      description: 'Een mooie verdagdag is reden voor een feestje. Bijvoorbeeld als je 10.000 dagen oud bent, of 25.000. Zet ze hier in je kalender.',
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-height: 100vh;
  background-color: #3ba8b5
}

#logo {
  max-width: 20%;
  float: right;
}
.section {
  padding: 2rem !important;
}
.content{
  padding: 0 20px;
}
#main-box {
  margin: auto;
}
</style>
