<template>

<div class="mt-4">
        <p>Deze site is gebouwd door <a href="https://www.twitter.com/woutervd" target="_blank" rel="noopener">Wouter van Dijke</a> naar aanleiding van mijn 10.000ste verdagdag op 12 mei 2022.</p>
        <p>Dank aan mijn vader Henk van Dijke voor het vieren van gekke gelegenheden en de term 'verdagdag'.</p>

  <article class="message is-dark mb-3">
    <div class="message-body p-3">
      <i>Alle informatie die je invoert op deze site wordt lokaal verwerkt, in je eigen browser. Er wordt geen persoonlijke informatie verstuurd of opgeslagen.</i>
    </div>
  </article>

    <p class="is-size-7"><i>Illustratie: <a href="https://www.flaticon.com/free-icons/celebration" title="celebration icons" target="_blank" rel="noopener">Becris - Flaticon</a></i></p>

  <router-link to="/" class="button is-primary"><span class="icon">
      <font-awesome-icon icon="arrow-left" />
  </span><span>Terug</span></router-link>

</div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'AboutPage',
  setup () {
    useMeta({ title: 'Over' })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
