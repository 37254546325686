<template>

        <p class="title is-4 my-3">Krijg een melding van de belangrijkste verdagdagen</p>
        <p>Een mooie verdagdag is reden voor een feestje. Bijvoorbeeld als je <b>10.000</b> dagen oud bent, of <b>12.345</b> of <b>25.000</b>. </p><p>Ontdek wanneer jouw verdagdagen zijn, of die van je vrienden, en zet ze gelijk in je kalender.</p>
        <button class="button is-primary mb-5" @click="this.$router.push('/start')">Dat wil ik!</button>

</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'HomePage',
  setup () {
    useMeta({ title: 'Home' })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
