import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faArrowLeft, faRotateRight, faCalendarPlus } from '@fortawesome/free-solid-svg-icons'

import { faTwitter } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
library.add(faArrowLeft, faRotateRight, faCalendarPlus, faTwitter)

/* import Bulma scss file */
require('@/assets/main.scss');

/* import Meta tag support */
import { createMetaManager } from 'vue-meta'

/* Router setup */

import HomePage from './components/Homepage.vue'
import SignUpFormMain from "@/components/SignUpFormMain"
import AboutPage from "@/components/About";

const routes = [
	{ path: '/', component: HomePage },
	{ path: '/start', component: SignUpFormMain },
	{ path: '/over', component: AboutPage },
]

const router = createRouter({
	history: createWebHistory(),
	routes, // short for `routes: routes`
})

const app = createApp(App)
	.use(createMetaManager())
	.use(router)

/* add font awesome icon component */
app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')